import React from "react";
import { useNavigate } from "react-router-dom";
import { DispatchContext, StateContext } from "../context/context";
import * as appContextAction from "../context/actions/appContextActions";
import { GLOBAL_ERROR_KEYS } from "../util/errors";
import * as RouteConfig from "../routes/config";
import { UNAUTHORIZED_KEY } from "util/constants";
import { getEntitlementValueWithPath } from "util/helper";
import { useFetch } from "components/network";

export enum HTTP {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  OPTIONS = "OPTIONS",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

/**
 * @param url  send endpoint importing from endpoint.ts
 * @param options
 */

//const BASE_URL = '/rcms/api/'
// const BASE_URL = "http://localhost/";
const BASE_URL = "https://attendance.smyrnaswebs.com/";

export const useFetchAPI = (
  url: string,
  options: any = { method: "GET", applicationId: "" }
) => {
  const navigate = useNavigate();
  const appContextDispatch = React.useContext(DispatchContext);
  const contextData = React.useContext(StateContext);
  const headerEntitlement = getEntitlementValueWithPath();

  let headerApplication =
    options.applicationId !== undefined &&
    options.applicationId !== null &&
    options.applicationId !== "-"
      ? { "X-RCMS-APPLICATION-ID": options.applicationId }
      : {};
  const authHeaders =
    contextData !== null &&
    contextData.authuser &&
    contextData.authuser.accessToken
      ? {
          headers: {
            Authorization: "Bearer " + contextData.authuser.accessToken,
            "Content-Type": "application/json",
            "RCMS-MODULE-CODE": headerEntitlement.MODULE,
            "RCMS-ACTION-KEY": headerEntitlement.ACTION,
            ...headerApplication,
          },
        }
      : {
          headers: {
            "Content-Type": "application/json",
          },
        };

  const { execute, response, headers, loading, error, reqHeaders } = useFetch(
    BASE_URL + url,
    { ...options, ...authHeaders }
  );

  React.useEffect(() => {
    if (error) {
      if (error.errorType === UNAUTHORIZED_KEY) {
        navigate(RouteConfig.LOGIN);
      } else {
        appContextDispatch(
          appContextAction.updateErrorId({
            errorCode:
              error && error.errorType
                ? error.errorType
                : GLOBAL_ERROR_KEYS.API_FAILURE,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return { execute, response, headers, loading, error, reqHeaders };
};
