export const UNAUTHORIZED_KEY = "UNAUTHORIZED";
export const API_STATUS_SUCCESS = "success";
export const API_STATUS_ERROR = "error";
export const NO_OF_RECORDS_PER_PAGE = 10;

export const SCREEN_TOAST_MESSAGE = {
  LOGOUT_MESSAGE: "Last logged in session on ",
  SUCCESS_INSERT_DATA: "Record Inserted Successfully!",
  SUCCESS_UPDATE_DATA: "Record Updated Successfully!",
  SUCCESS_DELETE_DATA: "Record Deleted Successfully!",
};

export const SYSTEM_PAGE_TITLE = {
  LOGIN_SCREEN: "Attendance App - Login",
  FORGOT_PASSWORD: "Attendance App - Forgot Password",
  REGISTRATION_SCREEN: "Attendance App - Registration",
  DASHBOARD: "Attendance App - Dashboard",
  USER_MANAGEMENT_ROLES: "Attendance App - Roles",
  USER_MANAGEMENT_TEAMS: "Attendance App - Teams",
  USER_MANAGEMENT_USERS: "Attendance App - User",
  USER_CREATE_USERS: "Attendance App - Create User",
  NEW_REQUEST: "Attendance App - New Request",
  VIEW_APPLICATION: "Attendance App - View Application",
  UPDATE_APPLICATION: "Attendance App - Update Application",
  CANCELLATION: "Attendance App - Cancellation",
};

export const USER_MANAGEMENT_ENT_MOD_KEY = "User_Management";
export const USER_MANAGEMENT_USER_KEY = "user";
export const USER_MANAGEMENT_ROLE_KEY = "roles";
export const USER_MANAGEMENT_TEAM_KEY = "teams";

export const TASK_MANAGEMENT_ENT_MOD_KEY = "TaskManagement";
export const TASK_MANAGEMENT_MY_TASK_KEY = "my_tasklist";
export const TASK_MANAGEMENT_RE_ASSIGN_TASK_KEY = "myteam_tasklist";

export const CANCELLATION_MOD_KEY = "Cancellation";
export const REPLACEMENT_MOD_KEY = "Replacement";

export const BTEC_ENT_MOD_KEY = "BTEC";
export const BTEC_KEY = "bt-ec";

export const PICK_CAPTURE_MOD_KEY = "Pick_and_Capture";
export const PICK_CAPTURE_KEY = "pick-and-capture";
export const PAC_SEARCH_APP_KEY = "search_application_pick_capture";

export const DEPOST_ENT_MOD_KEY = "DEPOST";
export const DEPOST_MOD_KEY = "depost";
export const DE_POST_SEARCH_APP_KEY = "search_application_depost";

export const SEARCH_APP_KEY = "search_application";

export const APPLICATION_STATUS_TYPES = {
  "in-progress": "InProgress",
  completed: "Completed",
  terminated: "Terminated",
  declined: "Declined",
};
