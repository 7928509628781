import React from "react";
import {
  Avatar,
  Box,
  Grid,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "components/material-ui";
import { memo } from "react";
import { getAuthUserData } from "util/helper";
import { StateContext } from "context/context";
import { useNavigate } from "react-router-dom";
import * as RoutePath from "routes/config";
import { confirmDialog } from "common/confirm_dialog";
import { motion } from "framer-motion/dist/framer-motion";
import "./index.css";

const Header = (props) => {
  const contextData = React.useContext(StateContext);
  const navigate = useNavigate();
  // const userGroupRef = useRef<any>();
  let authUser = getAuthUserData(contextData);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container item={true} className="headerDesign">
      <span className="headerAppInfo" id="applicationTitle"></span>

      <motion.div
        initial={{ x: 200 }}
        animate={{ x: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
        style={{ display: "flex" }}
      >
        {/* <Box className="loggedInSystemBox">
          <Icons.ComputerIcon />
          <Box
            className="viewMyAccessText"
            onClick={(e) => {
              userGroupRef?.current!.setOpen(
                authUser["fullName"],
                authUser["groups"]
              );
              setAnchorEl(null);
            }}
          >
            View My Access
          </Box>
        </Box> */}
        <Box onClick={handleClick}>
          <Box style={{ cursor: "pointer" }}>
            {`${authUser["username"]}`}
            <Avatar
              style={{
                width: "35px",
                height: "35px",
                backgroundColor: "#e92220",
                display: "inline-grid",
                marginLeft: "10px",
              }}
            >
              {authUser["username"] !== undefined
                ? authUser["username"].charAt(0)
                : ""}
            </Avatar>
          </Box>
        </Box>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          className="menuBottom0"
        >
          <>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              item={true}
              p={1}
              style={{ width: 350 }}
            >
              {/* <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <Avatar
                  style={{
                    width: "35px",
                    height: "35px",
                    backgroundColor: "#e92220",
                    display: "inline-grid",
                    marginLeft: "10px",
                    marginTop: 5,
                  }}
                >
                  {authUser["firstName"] !== undefined
                    ? authUser["firstName"].charAt(0)
                    : ""}
                </Avatar>
              </Grid> */}
              <Grid
                item
                xs={10}
                sm={10}
                md={10}
                lg={10}
                xl={10}
                style={{ paddingLeft: 5 }}
              >
                {/* <span
                  style={{
                    fontSize: 18,
                    display: "block",
                    color: "#e92220",
                  }}
                >
                  {`${authUser["firstName"]} ${authUser["lastName"]}`}
                </span> */}
                {/* <span
                  style={{
                    fontSize: 16,
                    display: "block",
                    color: "#646464",
                  }}
                >
                  {authUser["email"]}
                </span> */}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  marginTop: 15,
                  paddingLeft: 15,
                }}
              >
                <span
                  style={{
                    fontSize: 16,
                    display: "block",
                    color: "#646464",
                  }}
                >
                  Your Phone Number
                </span>
                <span
                  style={{
                    fontSize: 16,
                    display: "block",
                    color: "#000",
                  }}
                >
                  {authUser["mobileno"]}
                </span>
              </Grid>
              {/* <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  marginTop: 5,
                  paddingLeft: 15,
                }}
              >
                <span
                  style={{
                    fontSize: 16,
                    display: "block",
                    color: "#646464",
                  }}
                >
                  Your Staff ID
                </span>
                <span
                  style={{
                    fontSize: 16,
                    display: "block",
                    color: "#000",
                  }}
                >
                  {authUser["staffId"]}
                </span>
              </Grid> */}
            </Grid>
            <MenuItem
              onClick={() => {
                handleClose();
                confirmDialog("Are you sure you want to logout?", () => {
                  navigate(RoutePath.LOGIN);
                });
              }}
              style={{
                padding: 0,
                background: "#e92220",
              }}
            >
              <ListItem>
                <ListItemText primary="Logout" className="whiteColorSpan" />
              </ListItem>
            </MenuItem>
          </>
        </Menu>
      </motion.div>
    </Grid>
  );
};
export default memo(Header);
