import { Grid, Paper, Icons, InputLabel } from "components/material-ui";
import { MTextField, MButton, UnderlineText } from "components/ui";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SYSTEM_PAGE_TITLE, API_STATUS_SUCCESS } from "util/constants";
import { StateContext } from "context/context";
// import { APPLICATION_STATUS_TYPES } from "util/constants";
import { useDropzone } from "react-dropzone";
// import { GoogleMaps } from "./googleMap";
// import YouMarker from "../../../assets/images/you.png";
import Resizer from "react-image-file-resizer";
import { useFetchAPI } from "network/useFetchAPI";
import { APPLICATION_CREATE } from "network/Endpoints";
import { showToast } from "util/helper";
import { useNavigate } from "react-router-dom";
import { getAuthUserData } from "util/helper";
import * as RouteConfig from "routes/config";
import Loader from "components/loader";

const thumbsContainer: any = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb: any = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

declare global {
  interface Window {
    initMap: () => void;
  }
}

export const DashboardFunc = React.forwardRef((props: any, ref) => {
  const contextData = React.useContext(StateContext);

  const { control, getValues, trigger, clearErrors } = useForm({
    mode: "onTouched",
    defaultValues: {
      requestType: "",
      complaintDescription: "",
    },
  });
  const navigate = useNavigate();
  // const userGroupRef = useRef<any>();
  let authUser = getAuthUserData(contextData);
  const [errorFlag, setErrorFlag] = useState({ image: false, map: false });
  const [files, setFiles] = useState<any>([]);
  //@ts-ignore
  const [imagebase64, setImageBase64] = useState("");

  // const latLng = useRef({ lat: "", lng: "" });
  useEffect(() => {
    document.title = SYSTEM_PAGE_TITLE.NEW_REQUEST;
    // const script = document.createElement("script");

    // script.src =
    //   "https://maps.googleapis.com/maps/api/js?libraries=places,drawing&callback=initMap";
    // script.async = true;

    // document.body.appendChild(script);
    // window.initMap = initMap;

    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  const createBase64 = async (acceptedFiles) => {
    await Promise.all(
      acceptedFiles.map((image) => {
        return resizeFile(image);
      })
    ).then((acceptedFiles) => {
      setImageBase64(acceptedFiles[0]);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      createBase64(acceptedFiles);

      //@ts-ignore
      reader.onload = (e) => {
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            //@ts-ignore
            base64Image: e.target.result,
          })
        );

        setFiles([...acceptedFiles]);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    },
  });

  useEffect(() => {
    if (files.length > 0) {
      setErrorFlag({ ...errorFlag, image: false });
    }
  }, [files]);
  const thumbs = files.map((file: any) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
      <Icons.CloseIcon
        sx={{
          position: "absolute",
          color: "white",
          backgroundColor: "#333",
          fontSize: 15,
        }}
        onClick={() => setFiles([])}
      />
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  // function initMap() {
  //   var map = new google.maps.Map(
  //     document.getElementById("map") as HTMLElement,
  //     {
  //       zoom: 13,
  //       center: new google.maps.LatLng(25.19949381105219, 55.27968956834465),
  //       disableDoubleClickZoom: true,
  //       mapTypeControl: false,
  //       mapTypeControlOptions: {
  //         style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
  //         mapTypeIds: ["roadmap", "SATELLITE"],
  //         position: google.maps.ControlPosition.LEFT_TOP,
  //       },
  //       zoomControl: true,
  //       zoomControlOptions: {
  //         position: google.maps.ControlPosition.LEFT_CENTER,
  //       },
  //       gestureHandling: "greedy",
  //       scaleControl: true,
  //       streetViewControl: false,
  //       streetViewControlOptions: {
  //         position: google.maps.ControlPosition.LEFT_TOP,
  //       },
  //       fullscreenControl: true,
  //       fullscreenControlOptions: {
  //         position: google.maps.ControlPosition.LEFT_CENTER,
  //       },
  //       styles: [
  //         {
  //           stylers: [
  //             {
  //               visibility: "on",
  //             },
  //           ],
  //         },
  //         {
  //           elementType: "geometry",
  //           stylers: [
  //             {
  //               color: "#f5f5f5",
  //             },
  //           ],
  //         },
  //         {
  //           elementType: "labels.icon",
  //           stylers: [
  //             {
  //               visibility: "on",
  //             },
  //           ],
  //         },
  //         {
  //           elementType: "labels.text.fill",
  //           stylers: [
  //             {
  //               color: "#616161",
  //             },
  //           ],
  //         },
  //         {
  //           elementType: "labels.text.stroke",
  //           stylers: [
  //             {
  //               color: "#f5f5f5",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "administrative.land_parcel",
  //           elementType: "labels.icon",
  //           stylers: [
  //             {
  //               visibility: "on",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "administrative.land_parcel",
  //           elementType: "labels.text.fill",
  //           stylers: [
  //             {
  //               color: "#bdbdbd",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "poi",
  //           elementType: "geometry",
  //           stylers: [
  //             {
  //               color: "#eeeeee",
  //             },
  //             {
  //               weight: 8,
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "poi",
  //           elementType: "labels.icon",
  //           stylers: [
  //             {
  //               visibility: "on",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "poi",
  //           elementType: "labels.text.fill",
  //           stylers: [
  //             {
  //               color: "#757575",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "poi.park",
  //           stylers: [
  //             {
  //               weight: 8,
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "poi.park",
  //           elementType: "geometry",
  //           stylers: [
  //             {
  //               color: "#e5e5e5",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "poi.park",
  //           elementType: "labels.icon",
  //           stylers: [
  //             {
  //               visibility: "on",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "poi.park",
  //           elementType: "labels.text.fill",
  //           stylers: [
  //             {
  //               color: "#9e9e9e",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "road",
  //           elementType: "geometry",
  //           stylers: [
  //             {
  //               color: "#ffffff",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "road",
  //           elementType: "labels.icon",
  //           stylers: [
  //             {
  //               visibility: "on",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "road.arterial",
  //           elementType: "labels.icon",
  //           stylers: [
  //             {
  //               visibility: "on",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "road.arterial",
  //           elementType: "labels.text.fill",
  //           stylers: [
  //             {
  //               color: "#757575",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "road.highway",
  //           elementType: "geometry",
  //           stylers: [
  //             {
  //               color: "#dadada",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "road.highway",
  //           elementType: "labels.icon",
  //           stylers: [
  //             {
  //               visibility: "on",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "road.highway",
  //           elementType: "labels.text.fill",
  //           stylers: [
  //             {
  //               color: "#616161",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "road.local",
  //           elementType: "labels.icon",
  //           stylers: [
  //             {
  //               visibility: "on",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "road.local",
  //           elementType: "labels.text.fill",
  //           stylers: [
  //             {
  //               color: "#9e9e9e",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "transit.line",
  //           elementType: "geometry",
  //           stylers: [
  //             {
  //               color: "#e5e5e5",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "transit.line",
  //           elementType: "labels.icon",
  //           stylers: [
  //             {
  //               visibility: "on",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "transit.station",
  //           elementType: "geometry",
  //           stylers: [
  //             {
  //               color: "#eeeeee",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "transit.station",
  //           elementType: "labels.icon",
  //           stylers: [
  //             {
  //               visibility: "on",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "water",
  //           elementType: "geometry",
  //           stylers: [
  //             {
  //               color: "#c9c9c9",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "water",
  //           elementType: "geometry.fill",
  //           stylers: [
  //             { color: "#2f618a" },
  //             { saturation: -80 },
  //             { lightness: -15 },
  //           ],
  //         },
  //         {
  //           featureType: "water",
  //           elementType: "labels.icon",
  //           stylers: [
  //             {
  //               color: "#757575",
  //             },
  //           ],
  //         },
  //         {
  //           featureType: "water",
  //           elementType: "labels.text.fill",
  //           stylers: [
  //             {
  //               color: "#9e9e9e",
  //             },
  //           ],
  //         },
  //       ],
  //     }
  //   );

  //   var marker = new google.maps.Marker({
  //     map: map,
  //     draggable: true,
  //     icon: YouMarker,
  //     animation: google.maps.Animation.DROP,
  //   });

  //   google.maps.event.addListener(map, "dblclick", function (event) {
  //     marker.setOptions({ position: event.latLng, map: map });
  //     console.log("map dblclick", event.latLng);
  //   });

  //   google.maps.event.addListener(map, "click", function (event) {
  //     marker.setOptions({ position: event.latLng, map: map });
  //     console.log("map click", event.latLng);
  //   });

  //   google.maps.event.addListener(marker, "click", function (event) {
  //     marker.setPosition(event.latLng);
  //     console.log("marker click", event.latLng);
  //   });

  //   google.maps.event.addListener(marker, "position_changed", function (event) {
  //     //@ts-ignore
  //     latLng.current.lat = this.getPosition().lat();
  //     //@ts-ignore
  //     latLng.current.lng = this.getPosition().lng();
  //   });

  //   var input = document.getElementById("pac-input");
  //   //@ts-ignore
  //   var autocomplete = new google.maps.places.Autocomplete(input);
  //   //@ts-ignore
  //   map.controls[google.maps.ControlPosition.TOP].push(input);
  //   autocomplete.bindTo("bounds", map);

  //   autocomplete.addListener("place_changed", function () {
  //     marker.setVisible(false);
  //     var place = autocomplete.getPlace();
  //     if (!place.geometry) {
  //       // User entered the name of a Place that was not suggested and
  //       // pressed the Enter key, or the Place Details request failed.
  //       window.alert("No details available for input: '" + place.name + "'");
  //       return;
  //     }

  //     // If the place has a geometry, then present it on a map.
  //     if (place.geometry.viewport) {
  //       map.fitBounds(place.geometry.viewport);
  //     } else {
  //       //@ts-ignore
  //       map.setCenter(place.geometry.location);
  //       map.setZoom(17); // Why 17? Because it looks good.
  //     }
  //     marker.setPosition(place.geometry.location);
  //     marker.setVisible(true);
  //   });
  // }

  const {
    execute: createApplication,
    response: createApplicationResponse,
    loading: createApplicationLoading,
  } = useFetchAPI(APPLICATION_CREATE, {
    method: "POST",
  });

  useEffect(() => {
    if (!createApplicationLoading && createApplicationResponse) {
      let { status, message } = createApplicationResponse;
      if (status === API_STATUS_SUCCESS) {
        showToast("success", " Application Created Successfully.");
        navigate(RouteConfig.DASHBOARD);
      } else {
        showToast("error", message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createApplicationResponse, createApplicationLoading]);

  const submitRequest = () => {
    let requestType = getValues("requestType");
    let complaintDescription = getValues("complaintDescription");
    let flag = false;
    let validationFlag = {
      image: false,
      map: false,
    };
    //Validations
    if (requestType === "") {
      trigger("requestType");
      flag = true;
    }

    if (complaintDescription === "") {
      trigger("complaintDescription");
      flag = true;
    }

    if (files.length === 0) {
      validationFlag = { ...validationFlag, image: true };
      flag = true;
    }
    //@ts-ignore
    // if (latLng.current.lat === "") {
    //   validationFlag = { ...validationFlag, map: true };
    //   flag = true;
    // }
    setErrorFlag({ ...validationFlag });
    if (flag) {
      return;
    } else {
      debugger;
      let request = {
        requestType: requestType,
        image: imagebase64,
        //@ts-ignore
        lat: 0.0,
        //@ts-ignore
        lng: 0.0,
        desc: complaintDescription,
        userId: authUser["userId"],
        username: authUser["username"],
      };

      createApplication({
        data: request,
      });
      console.log(request);
    }
  };

  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
        <span style={{ marginBottom: 10, display: "block" }}>
          <UnderlineText>
            <span className="heading">New Request</span>
          </UnderlineText>
        </span>
      </Grid>

      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} mt={1}>
        <Paper className="paperFullWidth">
          <form onSubmit={() => {}} style={{ width: "100%" }}>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <MTextField
                name={"requestType"}
                label={"Request Type"}
                grid={4}
                required={true}
                control={control}
                onInputChange={() => {
                  clearErrors("requestType");
                }}
              />
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={4} xl={4}>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputLabel shrink id="mutiple-select-label">
                  <span
                    style={{ color: errorFlag.image ? "#b00020" : "#313131" }}
                  >
                    Upload Image
                  </span>
                  <span style={{ color: "red" }}> *</span>
                </InputLabel>
              </Grid>
              <section
                className="dropzone-container"
                style={{
                  border: errorFlag.image
                    ? "2px #b00020 solid"
                    : "1px #313131 solid",
                  width: "100%",
                }}
              >
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p style={{ fontSize: 12 }}>
                    Drag 'n' drop some image files here, or click to select
                    files
                  </p>
                </div>
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                  {files && <aside style={thumbsContainer}>{thumbs}</aside>}
                </Grid>
              </section>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                {errorFlag.image && (
                  <p
                    style={{
                      fontSize: 12,
                      color: "#b00020",
                      fontWeight: "600",
                      marginTop: -1,
                      marginLeft: 5,
                    }}
                  >
                    Image is required
                  </p>
                )}
              </Grid>
            </Grid>
            {/* <Grid container xs={12} sm={12} md={12} lg={4} xl={4}>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputLabel shrink id="mutiple-select-label">
                  <span
                    style={{ color: errorFlag.map ? "#b00020" : "#313131" }}
                  >
                    Select Location
                  </span>
                  <span style={{ color: "red" }}> *</span>
                  <span style={{ color: "#ddd" }}>
                    (Click on the map to select location)
                  </span>
                </InputLabel>
              </Grid>
              <div>
                <input
                  style={{
                    maxWidth: "300px !important",
                    borderRadius: 5,
                    marginTop: 10,
                  }}
                  className="form-control controls"
                  id="pac-input"
                  type="text"
                  placeholder="Search Location"
                />
              </div>

              <input
                type="hidden"
                id="lat"
                name="lat"
                style={{ width: "30%" }}
                value=""
              />
              <input
                type="hidden"
                id="lon"
                name="lon"
                style={{ width: "30%" }}
                value=""
              />
              <div
                id="location_content"
                style={{ textAlign: "center", color: "W" }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "300px",
                  marginLeft: 5,
                  borderRadius: 5,
                  border: "1px #313131 solid",
                }}
                id="map"
              ></div>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                {errorFlag.map && (
                  <p
                    style={{
                      fontSize: 12,
                      color: "#b00020",
                      fontWeight: "600",
                      marginTop: 0,
                      marginLeft: 5,
                    }}
                  >
                    Location is required
                  </p>
                )}
              </Grid>
            </Grid> */}
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <MTextField
                name={"complaintDescription"}
                label={"Description"}
                grid={4}
                textarea={true}
                areaRow={8}
                required={true}
                control={control}
                onInputChange={() => {
                  clearErrors("complaintDescription");
                }}
              />
            </Grid>

            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                item
                style={{
                  display: "inline-block",
                  alignSelf: "center",
                  marginTop: "2.4vh",
                }}
              >
                <MButton small={true} onClick={submitRequest}>
                  Submit
                </MButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Loader enable={createApplicationLoading} />
    </Grid>
  );
});
const Dashboard = React.memo(DashboardFunc);
export default Dashboard;
