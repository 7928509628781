import React, { useEffect, useState } from "react";
import { Grid } from "components/material-ui";
import {
  ExpandablePanel,
  MButton,
  // MButton,
  // MTextField,
  MashTablePro,
} from "components/ui";
import { getDateForDisp, showToast } from "util/helper";
import { useFetchAPI } from "network/useFetchAPI";
import { APPLICATION_UPDATE } from "network/Endpoints";
import { API_STATUS_SUCCESS } from "util/constants";

// , IconButton, Icons
interface DashBoardItemProps {
  getApplicationData: any;
  datelist: any;
  title: any;
  openApplication: any;
  authUser: any;
}

export const DashboardItemFunc = React.forwardRef(
  (props: DashBoardItemProps, ref) => {
    const [expanded, setExpanded] = useState(false);
    const { datelist, title, authUser, getApplicationData } = props;

    const {
      execute: approveReq,
      response: approveReqResponse,
      loading: approveReqLoading,
    } = useFetchAPI(APPLICATION_UPDATE, {
      method: "POST",
    });

    useEffect(() => {
      if (approveReqLoading === false && approveReqResponse) {
        let { status } = approveReqResponse;
        if (status === API_STATUS_SUCCESS) {
          getApplicationData();
          showToast("success", "Updated Successfully");
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approveReqResponse, approveReqLoading]);

    const commentsColumns = [
      {
        field: "classdate",
        title: "Date",
        width: "50%",
        render: (rowData) => (
          <span>{getDateForDisp(new Date(rowData.classdate))}</span>
        ),
      },
      {
        field: "status",
        title: "status",
        width: `${authUser["username"] === "bennys" ? "50%" : "50%"}`,
        render: (rowData) => (
          <>
            {authUser["username"] === "bennys" ? (
              <>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ marginTop: 10 }}
                >
                  <span>{rowData.status}</span>
                  <MButton
                    onClick={() => {
                      approveReq({
                        data: {
                          id: rowData.id,
                          status: "Approved",
                        },
                      });
                    }}
                    small={true}
                  >
                    Approve
                  </MButton>

                  <MButton
                    onClick={() => {
                      approveReq({
                        data: {
                          id: rowData.id,
                          status: "Cancelled",
                        },
                      });
                    }}
                    small={true}
                    style={{ marginLeft: 5 }}
                  >
                    Reject
                  </MButton>
                </Grid>
              </>
            ) : (
              <>
                <span
                  style={{
                    display: "block",
                    backgroundColor: `${
                      rowData.status === "Pending Approval"
                        ? "orange"
                        : rowData.status === "Approved"
                        ? "green"
                        : "Red"
                    }`,
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  {rowData.status}
                </span>
              </>
            )}
          </>
        ),
      },
    ];
    return (
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <ExpandablePanel
          marginTop={5}
          style={{ marginTop: 5 }}
          content={
            <>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <span
                  style={{
                    width: "100%",
                    display: "block",
                    backgroundColor: `green`,
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  {`Total Sessions: ${
                    datelist.filter((item) => item.status === "Approved").length
                  }`}
                </span>
                <MashTablePro
                  id="commentList"
                  rows={datelist}
                  columns={commentsColumns}
                  search={false}
                  height={600}
                />
              </Grid>
            </>
          }
          title={title}
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
        />
      </Grid>
    );
  }
);
const DashBoardItem = React.memo(DashboardItemFunc);
export default DashBoardItem;
