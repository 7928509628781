import { Grid, Paper } from "components/material-ui";
import {
  MButton,
  UnderlineText,
  CircularProgress,
  MDateField,
} from "components/ui";
import React, { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
import { SYSTEM_PAGE_TITLE } from "util/constants";
import * as RoutePath from "routes/config";
import { useNavigate } from "react-router-dom";
// import { APPLICATION_STATUS_TYPES } from "util/constants";
// import ModuleCards from "../general/moduleCards";
import DashBoardItem from "./dashBoardItem";
// import ToggleButton from "@mui/material/ToggleButton";
import { API_STATUS_SUCCESS } from "util/constants";
import * as Endpoints from "network/Endpoints";
import { convertDateObjToReqFormat, showToast } from "util/helper";
import { useFetchAPI } from "network/useFetchAPI";
import { StateContext } from "context/context";
import NoRecords from "assets/images/empty_apps.png";
import { useForm } from "react-hook-form";

import { getAuthUserData } from "util/helper";
import Loader from "components/loader";

export const DashboardFunc = React.forwardRef((props: any, ref) => {
  const contextData = React.useContext(StateContext);
  let authUser = getAuthUserData(contextData);
  const { control, handleSubmit } = useForm({
    mode: "onTouched",
  });

  useEffect(() => {
    document.title = SYSTEM_PAGE_TITLE.DASHBOARD;
    getApplicationData();
  }, []);

  const navigate = useNavigate();
  // let toggleOptions = ["All", "Waiting", "In Progress", "Completed"];

  const openApplication = (rowData, action) => {
    if (action === "view")
      navigate(RoutePath.VIEW_APPLICATION, {
        state: {
          applicationId: rowData.applicationId,
        },
      });
    if (action === "update")
      navigate(RoutePath.UPDATE_APPLICATION, {
        state: {
          applicationId: rowData.applicationId,
        },
      });
  };

  const [taskListData, setTaskListData] = useState<any>(null);

  const {
    execute: getApplicationData,
    response: getApplicationDataResponse,
    loading: getApplicationDataLoading,
  } = useFetchAPI(Endpoints.APPLICATION_GET, {
    method: "GET",
  });
  useEffect(() => {
    if (!getApplicationDataLoading && getApplicationDataResponse) {
      let { status, data, message } = getApplicationDataResponse;
      if (status === API_STATUS_SUCCESS) {
        if (data && data.length > 0) {
          const result = {};

          data.forEach((item) => {
            const date = new Date(item.classdate);
            const monthYearKey = `${date.toLocaleString("en-us", {
              month: "short",
            })}-${date.getFullYear()}`;

            if (!result[monthYearKey]) {
              result[monthYearKey] = [];
            }

            result[monthYearKey].push(item);
          });
          console.log(result);
          setTaskListData(result);
        }
      } else {
        showToast("error", message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationDataLoading, getApplicationDataResponse]);

  const {
    execute: insertDate,
    response: insertDateResponse,
    loading: insertDateLoading,
  } = useFetchAPI(Endpoints.APPLICATION_CREATE, {
    method: "POST",
  });

  useEffect(() => {
    if (insertDateLoading === false && insertDateResponse) {
      let { status } = insertDateResponse;
      if (status === API_STATUS_SUCCESS) {
        getApplicationData();
        showToast("success", "Request Sent Successfully!!!");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertDateResponse, insertDateLoading]);

  const onSubmit = (data) => {
    console.log(data);
    insertDate({
      data: {
        date: convertDateObjToReqFormat(data.sessiondate),
        status: "Pending Approval",
      },
    });
  };

  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
        <span style={{ marginBottom: 10, display: "block" }}>
          <UnderlineText>
            <span className="heading">Dashboard</span>
          </UnderlineText>
        </span>
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
        lg={4}
        xl={4}
        style={{ textAlign: "right" }}
      ></Grid>
      <Grid container columns={15}>
        <Grid item xs={3}>
          <div className="cssSelectedPaper"></div>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      {/* <ModuleCards /> */}

      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} mt={1}>
        <Paper className="paperFullWidth">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <MDateField
                name={"sessiondate"}
                label={"Select Date "}
                control={control}
                grid={4}
                required={true}
              />

              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <MButton
                  type={"submit"}
                  style={{
                    marginTop: 10,
                    width: "100%",
                  }}
                  disabled={false}
                >
                  {false ? (
                    //@ts-ignore
                    <CircularProgress colors={"white"} size={20} />
                  ) : (
                    "Submit"
                  )}
                </MButton>
              </Grid>
            </Grid>
          </form>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} mt={1}>
            {taskListData === null ? (
              <Grid
                container
                item={true}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ height: 240 }}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ textAlign: "center" }}
                >
                  <img
                    src={NoRecords}
                    alt="noRecord"
                    style={{ width: "30%" }}
                  />
                  <span style={{ display: "block" }}>No Sessions found!</span>
                </Grid>
              </Grid>
            ) : (
              <>
                {Object.entries(taskListData).map(([key, value]) => (
                  <DashBoardItem
                    getApplicationData={getApplicationData}
                    title={key}
                    datelist={value}
                    openApplication={openApplication}
                    authUser={authUser}
                  />
                ))}
              </>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Loader enable={getApplicationDataLoading} />
    </Grid>
  );
});
const Dashboard = React.memo(DashboardFunc);
export default Dashboard;
