//@ts-nocheck
import { Box } from "components/material-ui";
import { MTextField, MButton, CircularProgress } from "components/ui";
import { useForm } from "react-hook-form";
import { getRule } from "util/validations";
import packageJson from "../../../../package.json";
import Grid from "@mui/material/Grid";
import * as RouteConfig from "routes/config";
import { useNavigate } from "react-router-dom";
import EmpireAELogo from "assets/images/rjlogolg.png";
const LoginForm = ({ onSubmit, checkLoginLoading, OnError }) => {
  const { control, handleSubmit } = useForm({
    mode: "onTouched",
  });
  const navigate = useNavigate();
  return (
    <form onSubmit={handleSubmit(onSubmit, OnError)}>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "25vh" }}
        >
          <Box
            style={{
              padding: "3%",
              borderRadius: 20,
            }}
          >
            {/* <img
              src={EmpireAELogo}
              alt="Empire AE Logo"
              style={{ margin: "auto", width: "45vh", display: "block" }}
            /> */}
            <h1>Attendance</h1>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <span className="loginText1">Hi, Welcome Back</span>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <span className="loginText2">Enter your credentials to continue</span>
        </Grid>

        <Box style={{ marginTop: 20, width: "100%" }}>
          <MTextField
            name={"username"}
            label={"Username "}
            control={control}
            grid={12}
            required={true}
          />
        </Box>
        <Box style={{ marginTop: 10, width: "100%" }}>
          <MTextField
            password={true}
            name={"password"}
            label={"Password"}
            control={control}
            grid={12}
            required={true}
          />
        </Box>
        <Box
          style={{
            marginTop: 10,
            marginLeft: 5,
            marginRight: 5,
            width: "100%",
          }}
        >
          <MButton
            type={"submit"}
            style={{
              marginTop: 10,
              width: "100%",
            }}
            disabled={checkLoginLoading}
          >
            {checkLoginLoading ? (
              <CircularProgress colors={"white"} size={20} />
            ) : (
              "Login"
            )}
          </MButton>
        </Box>
      </Grid>
      {/* <Grid
        container
        className="loginText3"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 1,
          m: 1,
          bgcolor: "background.paper",
          borderRadius: 1,
        }}
      >
        <Grid item={true}>
          <span onClick={() => navigate(RouteConfig.FORGOTPASSWORD)}>
            Forgot Password?
          </span>
        </Grid>
        <Grid item={true}>
          <span onClick={() => navigate(RouteConfig.SIGNUP)}>
            Don't have an account?
          </span>
        </Grid>
      </Grid> */}
    </form>
  );
};

export default LoginForm;
